import React from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import Layout from '../../components/Layout';
import renderContent from '../../utils/rich-text-helpers';
import HomeSlider from '../../components/HomeSlider';
import image1 from '../../assets/homepage-svgs/img/image1.jpg';
import image2 from '../../assets/homepage-svgs/img/image2.jpg';
import image3 from '../../assets/homepage-svgs/img/image3.jpg';
import { getSeoConfig } from '../../constants/defaultSeoProps';
import Partner from '../../components/Partner';
import s from './Home.module.scss';

const slugify = (str) => {
  // Remove non-word characters and replace spaces with hyphens
  const slug = str
    .replace(/[^a-zA-Z0-9\s]/g, '')
    .replace(/\s+/g, '-')
    .toLowerCase();

  return slug;
};

const ourServices = [
  {
    image: image1,
    title: 'Professional Services',
  },
  {
    image: image2,
    title: 'Managed Services',
  },
  {
    image: image3,
    title: 'Staffing Solutions',
  },
];

const propTypes = {
  detail: PropTypes.shape({
    title: PropTypes.shape({
      json: PropTypes.shape({
        content: PropTypes.array,
      }).isRequired,
    }).isRequired,
    description: PropTypes.shape({
      json: PropTypes.shape({
        content: PropTypes.array,
      }).isRequired,
    }).isRequired,
    banner: PropTypes.shape({ fluid: PropTypes.shape({}) }).isRequired,
    seoImage: PropTypes.shape({
      fluid: PropTypes.shape({
        src: PropTypes.string.isRequired,
      }),
    }).isRequired,
    bannerTitle: PropTypes.string,
    bannerSubTitle: PropTypes.string,
    partners: PropTypes.array.isRequired,
    caseStudiesSlider: PropTypes.array.isRequired,
  }).isRequired,
  companyQuote: PropTypes.shape({
    footerDescription: PropTypes.shape({}).isRequired,
  }).isRequired,
};

const Home = ({ detail, companyQuote }) => {
  const {
    description,
    title,
    banner: bannerImage,
    bannerTitle,
    bannerSubTitle,
    partners: partnersImages,
    caseStudiesSlider: caseStudiesSliderImages,
  } = detail;

  const seoParams = {
    ogImagePath: '/og-images/home.png',
    ogTitle: 'Tech Holding: A Global Technology Solution Company',
    ogDesc:
      'Discover cutting-edge tech solutions & digital strategies with TechHolding. Reach out at info@techholding.co to ignite your digital journey.',
    ogPath: '/',
  };

  const updatedSeo = getSeoConfig(seoParams);

  return (
    <Layout
      isHomePage
      seo={updatedSeo}
      title={updatedSeo.openGraph.title}
      footerDescription={companyQuote.footerDescription}
    >
      {/* Hero Section */}
      <section className={s.heroSection}>
        <div className={s.heroContentOuter}>
          <div className={s.container}>
            <h1 className={s.titleHeight} data-aos="fade-up">
              {bannerTitle}
              <span>{bannerSubTitle}</span>
            </h1>
          </div>
        </div>
        <div className={s.heroItem}>
          <div className={s.heroImage}>
            <Image fluid={bannerImage.fluid} />
          </div>
        </div>
      </section>

      <div className={s.heroContent}>
        <div className={s.container}>
          <header className={s.head}>
            <div className={s.column}>
              <div className={s.title} data-aos="fade-up" data-aos-delay="300">
                {title?.json?.content.map((content) => renderContent(content))}
              </div>
            </div>
            <div className={s.column}>
              <div className={s.description} data-aos="fade-up" data-aos-delay="300">
                {description?.json?.content.map((content) => renderContent(content))}
              </div>
            </div>
          </header>
        </div>
      </div>

      {/* Services Section */}
      <section className={s.homeOurServices}>
        <h2>Our Services</h2>
        <div className={s.container}>
          <div className={s.content}>
            {ourServices.map((service) => (
              <div key={slugify(service.title)} className={s.column}>
                <div className={s.serviceItem}>
                  <div className={s.serviceImage}>
                    <img src={service.image} alt={service.title} />
                  </div>
                  <div className={s.serviceContent}>
                    <span className={s.serviceTitle}>{service.title}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Partner Section */}
      <Partner partnersImages={partnersImages} />

      {/* Client's Section */}
      <section className={s.homeCompanies}>
        <h2>Trusted by Leading Companies </h2>
        <HomeSlider caseStudiesSliderImages={caseStudiesSliderImages} />
      </section>
    </Layout>
  );
};

Home.propTypes = propTypes;
export default Home;
