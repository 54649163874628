import React from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import Slider from 'react-slick';

import s from './HomeSlider.module.scss';

const propTypes = {
  caseStudiesSliderImages: PropTypes.array.isRequired,
};

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 6,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const HomeSlider = ({ caseStudiesSliderImages }) => (
  <Slider {...settings} className="leading-companies-slider">
    {caseStudiesSliderImages.map((image) => (
      <div className="slider-img" key={image.title}>
        <Image fluid={image.fluid} className={s.img} />
      </div>
    ))}
  </Slider>
);

HomeSlider.propTypes = propTypes;
export default HomeSlider;
