import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Home from '../containers/Home';

const propTypes = {
  data: PropTypes.shape({
    contentfulHomePage: PropTypes.shape({
      description: PropTypes.shape({}),
      title: PropTypes.shape({}),
      banner: PropTypes.shape({ fluid: PropTypes.shape({}) }).isRequired,
      seoImage: PropTypes.shape({ fluid: PropTypes.shape({}) }).isRequired,
      bannerTitle: PropTypes.string,
      bannerSubTitle: PropTypes.string,
      partners: PropTypes.array.isRequired,
      caseStudiesSlider: PropTypes.array.isRequired,
    }),
    contentfulCompanyQuote: PropTypes.shape({
      footerDescription: PropTypes.shape({}).isRequired,
    }).isRequired,
  }).isRequired,
};

const IndexPage = ({ data }) => (
  <Home detail={data.contentfulHomePage} companyQuote={data.contentfulCompanyQuote} />
);

IndexPage.propTypes = propTypes;
export default IndexPage;

export const query = graphql`
  query {
    contentfulHomePage {
      banner {
        fluid(maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      description {
        json
      }
      title {
        json
      }
      bannerTitle
      bannerSubTitle
      partners {
        title
        fluid(maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      seoImage {
        fluid(maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      caseStudiesSlider {
        fluid(maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        title
      }
    }
    contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      footerDescription {
        json
      }
    }
  }
`;
