import React from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import { Link } from 'gatsby';
import s from './Partner.module.scss';

const propTypes = {
  partnersImages: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      fluid: PropTypes.shape({}),
    }),
  ).isRequired,
};

const Partner = ({ partnersImages }) => {
  const getPartnerWebsiteLink = (partnerTitle) => {
    switch (partnerTitle.toLowerCase()) {
      case 'aws':
        return '/services/aws';
      case 'snowflake':
        return '/services/snowflake';
      case 'google cloud platform':
        return '/services/gcp';
      default:
        return null;
    }
  };
  return (
    <section className={s.homeOurPartners}>
      <h2>Our Partners</h2>
      <div className={s.content}>
        {partnersImages.map((image) => (
          <div key={`${image.title}-${image.fluid.aspectRatio}`} className={s.column}>
            <div className={s.partnersImage}>
              <Link className={s.link} to={getPartnerWebsiteLink(image.title)}>
                <Image className={s.partnerImage} fluid={image.fluid} />
              </Link>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

Partner.propTypes = propTypes;
export default Partner;
